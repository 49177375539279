import React from 'react';
import { useNavigate } from "react-router-dom";
// import "./card1.css"

function ServiceCard() {
    const navigate = useNavigate();
    return (
        <div className="card1 bg-danger text-white">
            <div className="card-body1">
                <div style={{ color: "#EE4358", display: "flex", alignItems: "center" }}>
                    {/* <bold style={{ fontWeight: "900" }}>Get in touch</bold> */}
                    <img style={{ width: "100%" }} src="./letsFigure.png" />
                </div>
                <div style={{ width: "1px", backgroundColor: "#CCCCCC" }}></div>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    <p className="card-text1" style={{ color: "black", textAlign: "left" }}>
                        <bold style={{ color: "black", fontWeight: "800", fontSize: "16px" }}>Don’t Quite See What You Need Here?</bold><br />
                        We understand that every business is unique, and your needs might not fit neatly into a predefined category—and that’s okay! Even if you don’t see exactly what you’re looking for, we’re here to help.                 </p>
                    <button onClick={() => navigate("/contactUs")} className="`btn btn-light btn-red-background btn-modal">Hire us now</button>
                </div>
            </div>
        </div>
    );
}

export default ServiceCard;