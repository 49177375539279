import "./index.css"
import { useNavigate } from "react-router-dom";
const Footer = () => {
    const navigate = useNavigate();
    return (
        <footer
            style={{
                backgroundColor: '#EE4358',
                color: 'white',
                padding: '40px 20px',
                paddingLeft: "220px",
                position: 'relative',
                bottom: 0,
                width: '100%',
            }}
            className="body3"
        >
            <div className="container">
                <div className="row">
                    {/* Logo Section */}
                    <div className="col-lg-4 col-md-12 mb-4">
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <img
                                    src="./logo.png"
                                    alt="SVM Group Logo"
                                    style={{ height: '40px', marginBottom: '10px', marginRight: "10px" }}
                                />
                                <span style={{ fontWeight: 'bold' }}>SVMVentures</span>
                            </div>
                            <p style={{ margin: 0, fontWeight: '300', fontSize: '14px' }}>
                                Growing companies together
                            </p>
                        </div>
                    </div>

                    {/* Services Section */}
                    <div className="col-lg-2 col-md-4 mb-4">
                        <h5 style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '15px' }}>Services</h5>
                        <ul className="list-unstyled" style={{ padding: 0 }}>
                            <li><a href="#" style={{ color: 'white', textDecoration: 'none', fontSize: '14px' }}>AI Transformation</a></li>
                            <li><a href="#" style={{ color: 'white', textDecoration: 'none', fontSize: '14px', whiteSpace: "nowrap" }}>Product UI/UX & Development</a></li>
                            <li><a href="#" style={{ color: 'white', textDecoration: 'none', fontSize: '14px' }}>Marketing and Branding</a></li>
                            <li><a href="#" style={{ color: 'white', textDecoration: 'none', fontSize: '14px' }}>Go To Market</a></li>
                        </ul>
                    </div>

                    {/* Know More Section */}
                    <div className="col-lg-2 col-md-4 mb-4">
                        <h5 style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '15px', textAlign: "left" }}>Know More</h5>
                        <ul className="list-unstyled" style={{ padding: 0 }}>
                            <li><p onClick={() => navigate("/aboutUs")} style={{ color: 'white', textDecoration: 'none', fontSize: '14px', textAlign: "left", cursor: "pointer" }}> About Us</p></li>
                            <li><p onClick={() => navigate("/contactUs")} style={{ color: 'white', textDecoration: 'none', fontSize: '14px', textAlign: "left", cursor: "pointer" }}>Careers</p></li>
                            <li><p style={{ color: 'white', textDecoration: 'none', fontSize: '14px', textAlign: "left" }}>Privacy Policy</p></li>
                        </ul>
                    </div>

                    {/* Contact Section */}
                    <div className="col-lg-4 col-md-4 mb-4">
                        <h5 style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '15px', cursor: "pointer" }} onClick={() => navigate("/contactUs")}>Say Hello!</h5>
                        <p style={{ fontSize: '14px', marginBottom: '30px', textAlign: "left" }}>
                            <a href="mailto:connect@svmcorp.com" style={{ color: 'white', textDecoration: 'none' }}>
                                contact@svmcorp.com
                            </a>
                        </p>
                    </div>
                </div>

                {/* Footer Bottom */}
                <div className="row mt-4">
                    <div className="col footer-last-row" style={{ gap: "30px" }}>
                        <p style={{ fontSize: '14px', margin: 0 }}>© Copyright 2024 | Designed & Developed by SVM Ventures | All Right Reserved</p>
                        <div style={{ display: 'flex', gap: '10px', marginRight: "20%" }}>
                            <a href="https://www.linkedin.com/company/svm-ventures" target={"_blank"} style={{ color: 'white', fontSize: '20px' }}>
                                <img className="bi bi-linkedin" src="./linkeldn.png" />
                            </a>
                            <a href="https://www.instagram.com/svmventures/" target={"_blank"} style={{ color: 'white', fontSize: '20px' }}>
                                <img className="bi bi-linkedin" src="./insta.png" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </footer >
    );
};

export default Footer;
