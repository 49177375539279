import React from 'react';
import "./card1.css"
import { useNavigate } from "react-router-dom";

function Card1() {
    const navigate = useNavigate();
    return (
        <div className="card1 bg-danger text-white">
            <div className="card-body1">
                <div style={{ color: "#EE4358", display: "flex", alignItems: "center" }}>
                    {/* Why is <bold style={{ fontWeight: "900" }}>SVM</bold> Ventures your go to market partner? */}
                    <img src="./svmtext.png" />
                </div>
                <div className="divider" style={{ width: "1px", backgroundColor: "#CCCCCC" }}></div>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    <p className="card-text1" style={{ color: "black", textAlign: "left" }}>
                        <bold style={{ color: "black", fontWeight: "800", fontSize: "16px" }}>Your Dedicated Go To Market Team</bold><br />
                        We are your all-in-one partner for scaling your business. From designing a standout UI/UX for your application to driving inbound leads with strategic marketing, and even raising capital to fuel your growth—we cover it all. With business needs evolving constantly, why spend time building in-house teams? Access our expert services on demand and scale seamlessly.
                    </p>
                    <button onClick={() => navigate("/ourServices")} className="btn btn-light btn-red-background btn-modal">Explore our services</button>
                </div>
            </div>
        </div>
    );
}

export default Card1;