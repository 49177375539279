import React, { useState, useEffect } from "react";
import Card from "./card";
import Card1 from "./card1";
import Card2 from "./card2";
import "./index.css"
import { useNavigate } from "react-router-dom";
const Body = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const navigate = useNavigate();

    const headlines = ["marketing potential", "product experience", "go to market strategy"];

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % headlines.length);
        }, 2000); // Change headline every 1 second

        return () => clearInterval(interval); // Cleanup on unmount
    }, [headlines.length]);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const isMobileView = screenWidth < 998;

    return (
        <div className="body2">
            {/* Top Section with Animation */}
            <section
                style={{
                    backgroundColor: "#EE4358",
                    color: "white",
                    textAlign: "center",
                    padding: "140px 20px",
                    position: "relative",
                }}
            >
                <h1 style={{ fontSize: "6.5rem" }} className="responsive-heading">
                    Maximising your
                </h1>
                <div
                    style={{
                        position: "relative",
                        height: "124px", // Ensure enough space for the animation
                        overflow: "hidden",
                        marginBottom: "32px"
                    }}
                    className="responsive-height-animation"
                >
                    {headlines.map((text, index) => (
                        <h1
                            key={index}
                            style={{
                                fontSize: "6.5rem",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                opacity: activeIndex === index ? 1 : 0,
                                transform:
                                    activeIndex === index
                                        ? "translateY(0)"
                                        : "translateY(100%)",
                                transition: "opacity 0.5s, transform 0.5s",
                            }}
                            className="responsive-heading"
                        >
                            {text}
                        </h1>
                    ))}
                </div>
                <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
                    <button
                        style={{
                            padding: "10px 20px",
                            backgroundColor: "white",
                            // color: "#EE4358",
                            color: "black",
                            border: "none",
                            borderRadius: "5px",
                            fontSize: "1rem",
                            cursor: "pointer",
                        }}
                        className="btn-white-background"
                        onClick={() => navigate("./contactUs")}
                    >
                        Get in touch
                    </button>
                    <button
                        style={{
                            padding: "10px 20px",
                            backgroundColor: "transparent",
                            color: "white",
                            border: "1px solid white",
                            borderRadius: "5px",
                            fontSize: "1rem",
                            cursor: "pointer",
                        }}
                        onClick={() => navigate("./ourServices")}
                        className="btn-red-background"
                    >
                        Explore our services
                    </button>
                </div>
                {/* Add animation logic here */}
            </section>
            <section>
                <div className="image-container">
                    <img src="./polygun.png" alt="polygun" style={{ position: "relative", }} />
                </div>
            </section>

            {/* Metrics Section */}
            <section style={{ padding: "120px 20px", marginLeft: "200px", marginRight: "200px" }} className="matrix-wrappper">
                <h2 style={{ textAlign: "center", marginBottom: "40px" }} className="matrix-text">
                    Empowering global businesses to deliver exceptional product experiences for their customers.
                </h2>
                <div
                    className="mobile-matrices"
                    style={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexWrap: "wrap",
                        textAlign: "center",
                    }}
                >
                    {[
                        { metric: "91%", label: "AI Transformation Rate" },
                        { metric: "30+", label: "Years of Experience" },
                        { metric: "80%", label: "Client Retention Rate" },
                        { metric: "Global", label: "Reach Across Industry" },
                    ].map((item, index) => (
                        <div key={index} style={{ margin: "10px" }}>
                            <h3 style={{ fontSize: "4rem", marginBottom: "10px" }} className="matrix-num">{item.metric}</h3>
                            <p style={{ fontWeight: "600" }}>{item.label}</p>
                        </div>
                    ))}
                </div>
            </section>

            <section>
                <div className="image-container">
                    <img src="./polygon2.png" alt="polygun" style={{ position: "relative", }} />
                </div>
            </section>

            {/* Services Section */}
            <section style={{ backgroundColor: "#EE4358", color: "white", padding: "60px 20px" }}>
                <h2 style={{ textAlign: "center", marginBottom: "40px" }} className="text-mobile-services">Why hire? We’re your team!</h2>
                <p style={{ textAlign: "center", marginBottom: "40px" }} >
                    4 Core Service Areas
                </p>
                {
                    !isMobileView ?
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexWrap: "wrap",
                                gap: "20px",
                            }}
                        >
                            {[
                                {
                                    title: "AI Transformation & Implementation",
                                    description: "Help businesses integrate AI for innovation and growth.",
                                    hoverDescription: "We help businesses integrate AI to drive innovation, optimize operations, and unlock growth. From custom AI solutions like predictive analytics to customer support automation and data-driven decision-making, our end-to-end implementation transforms your business for the new AI age.",
                                    src: "./1.png"
                                },
                                {
                                    title: "Product UI/UX & Development",
                                    description: "Get elevated UI/UX designs for your product.",
                                    hoverDescription: "We focus on elevating your product's design to enhance engagement and user retention, tailoring each feature to your customers' needs. We equally help in engineering and developing your next idea into a functional and scalable product.",
                                    src: "./2.png"
                                },
                                {
                                    title: "Marketing and Branding",
                                    description: "Grow your customer base and boost revenue.",
                                    hoverDescription: "Our specialty is building strong campaigns, messaging that authentically describe your business, and turning your creative, branding, and video needs into reality all in one place with our own SVM Studio team.",
                                    src: "./5.png"
                                },
                                {
                                    title: "Go To Market",
                                    description: "Scale your business with our GTM services.",
                                    hoverDescription: "We help businesses of all stages in growth strategy, business development, navigating venture capital and legal compliance. Our advising team helps business owners stay ahead of their funding goals, and remove barriers of complicated legal product compliance and scale companies through strategy and analysis.",
                                    src: "./6.png"
                                }
                            ].map((service, index) => (
                                <div
                                    key={index}
                                    style={{
                                        color: "#262626",
                                        borderRadius: "10px",
                                        padding: "20px",
                                        maxWidth: "270px",
                                        textAlign: "left",

                                    }}
                                    className="service-tile"
                                >
                                    <div className="normal-content">
                                        <img src={service.src} alt="index" />
                                        <h3 style={{ marginBottom: "10px", marginTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>{service.title}</h3>
                                        <p style={{ textAlign: "left" }}>{service.description}</p>
                                    </div>
                                    <div className="hover-content">
                                        <h3 style={{ color: "white", marginBottom: "10px", marginTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>{service.title}</h3>
                                        <p style={{ fontSize: "12px", color: "white", overflow: "hidden", textAlign: "left" }}>{service.hoverDescription}</p>
                                    </div>
                                </div>
                            ))}
                            <div>
                                <Card1 />
                            </div>
                        </div> :
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexWrap: "wrap",
                                gap: "20px",
                            }}
                        >
                            {[
                                {
                                    title: "AI Transformation & Implementation",
                                    hoverDescription: "We help businesses integrate AI to drive innovation, optimize operations, and unlock growth. From custom AI solutions like predictive analytics to customer support automation and data-driven decision-making, our end-to-end implementation transforms your business for the new AI age.",
                                    src: "./1.png"
                                },
                                {
                                    title: "Product UI/UX & Development",
                                    hoverDescription: "We focus on elevating your product's design to enhance engagement and user retention, tailoring each feature to your customers' needs. We equally help in engineering and developing your next idea into a functional and scalable product.",
                                    src: "./2.png"
                                },
                                {
                                    title: "Marketing and Branding",
                                    hoverDescription: "Our specialty is building strong campaigns, messaging that authentically describe your business, and turning your creative, branding, and video needs into reality all in one place with our own SVM Studio team.",
                                    src: "./5.png"
                                },
                                {
                                    title: "Go To Market",
                                    hoverDescription: "We help businesses of all stages in growth strategy, business development, navigating venture capital and legal compliance. Our advising team helps business owners stay ahead of their funding goals, and remove barriers of complicated legal product compliance and scale companies through strategy and analysis.",
                                    src: "./6.png"
                                }
                            ].map((service, index) => (
                                <div
                                    key={index}
                                    style={{
                                        color: "#262626",
                                        borderRadius: "30px",
                                        padding: "20px",
                                        // maxWidth: "270px",
                                        textAlign: "left",
                                        // border: "1px solid white"

                                    }}
                                    className="service-tile"
                                >
                                    <div className="">
                                        <img src={service.src} alt="index" />
                                        <h3 style={{ marginBottom: "10px", marginTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>{service.title}</h3>
                                        <p style={{ textAlign: "left" }}>{service.hoverDescription}</p>
                                    </div>
                                    {/* <div className="hover-content">
                             <h3 style={{ color: "white", marginBottom: "10px", marginTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>{service.title}</h3>
                             <p style={{ fontSize: "12px", color: "white", overflow: "hidden", }}>{service.hoverDescription}</p>
                         </div> */}
                                </div>
                            ))}
                            <div>
                                <Card1 />
                            </div>
                        </div>

                }

            </section>


            <section>
                <div className="image-container">
                    <img src="./polygun.png" alt="polygun" style={{ position: "relative", }} />
                </div>
            </section>

            {/* Testimonials Section */}
            {/* <section style={{ backgroundColor: "#fff8f5", padding: "60px 20px" }}>
                <h2 style={{ textAlign: "center", marginBottom: "40px" }}>Clients who trust us</h2>
                <div style={{ textAlign: "center", marginBottom: "40px" }}>
                    <p style={{ fontSize: "1.2rem", marginBottom: "20px" }}>
                        "Their UI/UX redesign increased our app’s user engagement by 35%. SVM Ventures understands user needs and delivers innovative, user-friendly designs."
                    </p>
                    <p style={{ fontWeight: "bold" }}>Adam Markowitz</p>
                    <p>Co-founder & CEO, Data</p>
                </div>
                <div style={{ textAlign: "center" }}>
                    <button style={{ margin: "0 10px", cursor: "pointer" }}>&lt;</button>
                    <button style={{ margin: "0 10px", cursor: "pointer" }}>&gt;</button>
                </div>
            </section> */}

            {/* Contact Section */}
            {/* <section style={{ backgroundColor: "#d9534f", color: "white", padding: "60px 20px" }}>
                <h2 style={{ textAlign: "center", marginBottom: "20px" }}>Get in touch</h2>
                <p style={{ textAlign: "center" }}>
                    Your Dedicated Go To Market Team
                </p>
                <div style={{ textAlign: "center" }}>
                    <button
                        style={{
                            padding: "10px 20px",
                            backgroundColor: "white",
                            color: "#d9534f",
                            border: "none",
                            borderRadius: "5px",
                            fontSize: "1rem",
                            cursor: "pointer",
                        }}
                    >
                        Hire us now
                    </button>
                </div>
            </section> */}

            <section style={{ paddingTop: "120px", background: "#FFF5EE", display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column", alignItems: "center" }}>
                <div className="text-client">Client Who trust Us</div>
                <Card />
                <div style={{ marginTop: "120px" }}>
                    <Card2 />
                </div>
            </section>
            <section style={{ marginTop: "120px" }}>
                <div className="image-container">
                    <img src="./polygon2.png" alt="polygun" style={{ position: "relative", }} />
                </div>
            </section>
        </div>
    );
};

export default Body;
