import React from 'react';
import "./card1.css"
import { useNavigate } from "react-router-dom";

function Card2() {
    const navigate = useNavigate();
    return (
        <div className="card1 bg-danger text-white">
            <div className="card-body1">
                <div style={{ color: "#EE4358", display: "flex", alignItems: "center" }}>
                    {/* <bold style={{ fontWeight: "900" }}>Get in touch</bold> */}
                    <img src="./getIntouch.png" />
                </div>
                <div style={{ width: "1px", backgroundColor: "#CCCCCC" }}></div>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    <p className="card-text1" style={{ color: "black", textAlign: "left" }}>
                        <bold style={{ color: "black", fontWeight: "800", fontSize: "16px" }}>Your Dedicated Go To Market Team</bold><br />
                        Accelerate your product goals and connect with customers who will love your brand. Our approach is powered by People, Process, and Precision—everything you need to drive growth and capture market share effectively.                    </p>
                    <button onClick={() => navigate("/contactUs")} className="`btn btn-light btn-red-background btn-modal">Hire us now</button>
                </div>
            </div>
        </div>
    );
}

export default Card2;