import React, { useState } from 'react';
import './card.css'; // Import your CSS file

function Card() {
    const data = [
        {
            text: "I recommend SVM Ventures for so many reasons, including Radhika's expertise, experience, work style, and demeanor. She deeply understands the founders journey, start-up landscape and marketing. I looked forward to all the communications I had with Radhika, her strategies also made our investors happy, they were data driven paired with her strong instincts about marketing. Go to market is very confusing to tackle and she made it simple and focused for our early stage company",
            name: "Romina",
            title: "Founder, CEO of FemTech Company",
        },
        {
            text: "We started working with Radhika while bootstrapping our venture to understand our product market fit. She not only helped us define focused target market and messaging that increased overall sales momentum but also went above and beyond in grooming us to secure pre-seed funding. We really admired how persistent she was in finding solutions for us in the technology partnership side of business even when there was lack of communication from that side of industry. It helped us achieve our goals and build stronger technology alliances for our product roadmap.",
            name: "Brett & Travis",
            title: "Co-Founders of Deep Tech AI Company ",
        },
        {
            text: "Radhika and her team became the full marketing force we needed to launch our B2C company.  It's very easy to see why people love working with Radhika, she immediately understood our business goals and was able to advise on where we needed to go, how to develop our brand and grow our customers. In one of the campaigns she led for us, there was a 70% increase in our product sales. She is not like any other marketing agencies we have worked with before, she really pays attention to your needs and delivers on your company values. We will continue to work with her as our company has truly grown with her help.",
            name: "Stacy",
            title: "CEO of Fashion E-commerce Company",
        },
        {
            text: `I have been working with Radhika for almost 6 months now at our company, a local e-commerce marketplace startup. Radhika worked for us as a fractional Chief Marketing Officer and is in an advisory role for our company. When we were looking for a marketing officer, we met Radhika, and our company's vision matched perfectly with her ideas. Instead of having to hire a full time CMO, SVM Ventures services were able better align with our goals. Radhika created a solid marketing strategy including customer value exercise, GTM framework, and social media presence. Radhika's understanding of business and customer value helped us make crucial decisions for GTM. I would recommend SVM Ventures to anyone looking for growth marketing services`,
            name: "Raj",
            title: "Co-Founder Ecommerce Company",
        }
    ];
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    };

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
    };

    const currentCardData = data[currentIndex];

    return (
        <div className="card-container">
            <div className="card mobile-card-review">
                <p className="card-text">{currentCardData.text}</p>
            </div>
            <div className="card-footer">
                {/* <img className="card-avatar" src={currentCardData.avatar} alt={currentCardData.name} /> */}
                <p className="card-name">{currentCardData.name}</p>
                <p className="card-title">{currentCardData.title}</p>
            </div>

            <div className="card-navigation">
                <img style={{ height: "36px" }} src="./left.png" className="card-button" onClick={handlePrevClick} />
                <img style={{ height: "36px" }} src="./right.png" className="card-button" onClick={handleNextClick} />
            </div>
        </div>
    );
}

export default Card;