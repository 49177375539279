import './App.css';
import Header from './Components/header';
import Footer from './Components/footer';
import { Routes, Route } from 'react-router-dom';
import Body from './Components/body';
import Section1AboutUs from './Components/aboutUs';
import ContactUs from './Components/contactUs';
import Services from './Components/ourServices';

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Body />} />
        <Route path="/aboutUs" element={<Section1AboutUs />} />
        <Route path="/ourServices" element={<Services />} />
        <Route path="/contactUs" element={<ContactUs />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
