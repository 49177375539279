import React from 'react';

const FounderNote = () => {
    return (
        <div className="">
            <div
                className="text-center p-5"
                style={{ backgroundColor: '#EE4358', color: 'white', borderRadius: '8px' }}
            >
                <div className="d-flex align-items-center justify-content-center mb-3">
                </div>
                <h2 className="fw-bold">A Note from our Founder</h2>
                <p className="fw-light" style={{ fontStyle: 'italic' }}>
                    Here is why I started SVM Ventures...
                </p>
                <p className="mt-4">
                    Growing up in the Bay Area, I witnessed many remarkable companies rise and fall. What
                    intrigued me most was understanding why some succeed while others, with similar ideas and
                    teams, didn’t. It always came down to strategy and timing.
                </p>
                <p>
                    Moving on to working at the largest tech companies in Go-To-Market, I saw firsthand how
                    overwhelming growth can be—especially for businesses trying to connect with their
                    customers while juggling all the moving parts. I realized I could help businesses navigate
                    that journey, without the complexity of hiring or managing multiple teams.
                </p>
                <p>
                    Success lies in blending people, creativity, technology, and data to create meaningful,
                    lasting impact. That’s why I founded SVM Ventures—to bring together a team that’s the
                    partner businesses need to grow, scale, and stay focused on what matters most: their
                    customers.
                </p>
            </div>
        </div>
    );
};

export default FounderNote;
