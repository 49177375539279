import React from 'react';
import Card from '../body/card';
import Card2 from '../body/card2';
import './index.css'; // Include your styles
import ServiceCard from './serviceCard';
import { useNavigate } from "react-router-dom";
const Services = () => {
    const navigate = useNavigate();

    const sections = [
        {
            title: "AI Strategy & Implementation",
            description:
                "We help businesses take the first steps with AI—from assessing readiness to building and implementing solutions. Whether you need a roadmap or custom AI tools, we align every step with:",
            buttonText: "Have a product idea? Let's chat",
            columns: [
                {
                    subTitle: "AI Strategy Development",
                    subDescription:
                        "We help businesses define a clear, actionable AI roadmap tailored to your organization’s unique needs. This includes:",
                    points: [
                        "Identifying opportunities where AI can optimize operations or create new value.",
                        "Technology selection and architecture planning.",
                        "Aligning AI initiatives with your long-term business objectives.",
                    ],
                },
                {
                    subTitle: "AI Design, Implementation & Engineering",
                    subDescription:
                        "We turn AI strategies into actionable solutions by designing and building systems that fit your needs. Our services include:",
                    points: [
                        "AI Solution Prototyping: Launch pilot programs to test feasibility and fine-tune use cases.",
                        "Process Automation: Optimize workflows with intelligent automation for greater efficiency.",
                        "AI-Powered Tools: Develop and deploy applications like chatbots, virtual agents, and advanced BI tools.",
                        "Data Utilization: Harness and refine your data to generate meaningful, actionable insights.",
                        "System Integration: Integrate AI seamlessly into your existing platforms and infrastructure.",
                    ],
                },
            ],
        },
        {
            title: "Product UI/UX & Development",
            description:
                "Bringing your vision to life is one of the hardest parts of starting a business. Whether it’s a deep tech AI product or a user-centric application, we specialize in turning your ideas into functional and visually stunning realities. Our UI/UX team and full stack engineering team ensures your product idea aligns with your users and delivers an intuitive experience they will love.",
            buttonText: "Have a product idea? Let's chat",
            columns: [
                {
                    subTitle: "Product Research & Design",
                    subDescription: "From initial wireframes to polished designs, we combine deep market insights with thoughtful design to create seamless user experiences for AI-powered tools and innovative applications. Let us turn your vision into a product that’s as functional as it is unforgettable.",
                    points: [

                    ],
                },
                {
                    subTitle: "Engineering & Development",
                    subDescription: "We do end to end engineering and development of web applications, mobile, websites and much more.",
                    points: [

                    ],
                },
            ],
        },
        {
            title: "Marketing, Branding, SVM Studio",
            description: "We bring together all the pieces that’s needed to build market presence while achieving sales growth for your business.",
            buttonText: "Get in touch",
            columns: [
                {
                    subTitle: "Brand Identity & Design",
                    subDescription: "We craft a distinctive brand identity that not only resonates with your audience but also stands out in a crowded market. From logos to full visual systems, we design every element to reflect your brand’s values and connect with your customers.",
                    points: [

                    ],
                },
                {
                    subTitle: "Digital Campaigns",
                    subDescription: "Our data-driven digital marketing includes two service areas:",
                    points: [
                        "Campaign Strategy",
                        "Campaign Execution",
                        "We design and deliver tailored campaigns that boost brand awareness, generate leads, and drive conversions for your target audience. We optimize every touchpoint for measurable results and impactful growth.",

                    ],
                },
                {
                    subTitle: "SVM Studio: Content Creation",
                    subDescription: "We produce compelling content that engages, educates, and converts your audience. Specializing in short-form videos, graphics, and multimedia content, we ensure every piece resonates with your brand’s voice and goals.",
                    points: [
                    ],
                },
                {
                    subTitle: "SVM Studio: Product & Service Photography",
                    subDescription: "We create stunning product and service photography that brings your brand’s story to life. From e-commerce to social media, our high-quality images are designed to captivate, inspire trust, and drive engagement, all while authentically representing your offerings.",
                    points: [


                    ],
                },
                {
                    subTitle: "Performance Optimization",
                    subDescription: "We continuously analyze and refine your digital marketing efforts to maximize impact. Through ongoing optimization, we ensure your campaigns are always performing at their best, driving sustainable results.",
                    points: [


                    ],
                },
            ],
        },
        {
            title: "Go To Market",
            description: "A strong go-to-market strategy can change the trajectory of your company. It touches every part of your business and evolves with your goals. We know there’s no one-size-fits-all approach, which is why we offer a one-stop-shop for all your strategic needs. From market positioning to capital management, we act with urgency to drive your success and accelerate growth at every stage",
            buttonText: "Get in touch",
            columns: [
                {
                    subTitle: "Fundraising support",
                    subDescription: "We help position your business for success by developing a messaging strategy that aligns with your funding stage and growth trajectory. Our experts work with you to create a compelling pitch deck that resonates with investors and sets you apart. From crafting your outreach plan to guiding you through due diligence, we support you every step of the way in securing the capital needed to fuel your growth.",
                    points: [

                    ],
                },
                {
                    subTitle: "Growth & Go To Market Strategy",
                    subDescription: "If you have a small but mighty team but need more direction on how to execute effectively, we’re here to help. We work with companies to unlock their full potential with tailored growth and go-to-market strategies. Whether launching a new product, entering a new market, or scaling an existing offering, we provide actionable insights and data-driven plans to achieve your goals. Our expertise includes:",
                    points: [
                        "Market Research & Analysis: Understanding your target audience, competition, and opportunities.",
                        "Positioning & Messaging: Crafting compelling narratives that resonate with your audience.",
                        "Business Development & Strategic Partnerships: Identifying and building relationships with key partners to expand your reach and accelerate growth.",
                        "Channel Strategy: Identifying and optimizing the best channels to reach your customers.",
                        "Demand Generation Strategy: Driving awareness, leads, and conversions through innovative campaigns.",
                        "Performance Optimization: Monitoring results and refining strategies for sustainable growth.",
                    ],
                },
                {
                    subTitle: "Product Compliance & Regulatory Expertise",
                    subDescription: "Our team is dedicated to helping your business navigate the complexities of legal and regulatory frameworks with confidence. We understand that compliance can be challenging, so we take the burden off your shoulders, allowing you to focus on growth and innovation. Our areas of expertise include:",
                    points: [
                        "Regulatory Compliance: Navigating FTC, FDA, and other industry-specific requirements including data privacy and security standards.",
                        "Advertising & Promotional Strategies: Crafting campaigns that align with legal and regulatory standards while maximizing impact.",
                        "Direct-to-Consumer (DTC) & HCP Communication: Ensuring clear, compliant messaging avoiding misleading or deceptive claims, and ensuring transparent communication with your clients and investors.",
                        "Risk Mitigation in Promotional Materials: Identifying and addressing potential compliance risks in marketing assets.",
                        "Compliance Training & Auditing: Conducting regular audits of your product and marketing materials, and providing ongoing training for your team to stay up to date with changing regulations.",
                        "We partner with you to minimize risks and create opportunities, helping your business thrive in a competitive landscape.",
                    ],
                },
                {
                    subTitle: "Capital Strategy & Advisory",
                    subDescription: "Our clients choose this service for expert CFO support that optimizes their financial strategy and operations. Whether you need strategic guidance on fundraising, assistance managing your deal room, crafting quarterly communications with investors, or navigating growth challenges, we provide tailored advice to meet your company’s specific needs.",
                    points: [
                        "Fundraising Strategy: Guidance on securing capital and structuring fundraising rounds to align with business goals.",
                        "Deal Room Management: Streamlining deal room processes for efficient fundraising or M&A activities.",
                        "Investor Communications: Crafting quarterly reports and communication plans to keep investors informed and engaged.",
                        "Financial Planning & Strategy: Financial modeling, forecasting, and budgeting to guide business decisions and growth.",
                        "Growth & Profitability Management: Optimizing operations and managing growth for sustainable profitability.",
                        "Financial Reporting & Compliance: Ensuring clear, compliant financial reporting for stakeholders.",
                        "Cost Optimization: Identifying and implementing strategies to reduce costs and improve efficiency.",
                        "M&A Advisory: Strategic financial advice during mergers, acquisitions, or fundraising activities.",
                        "Let us provide the financial expertise you need to navigate challenges, plan for the future, and achieve sustainable growth.",
                    ],
                },
            ],
        },
        // Add additional sections here similarly...
    ];


    return (
        <>
            <div className="app-container">
                <header className="header">
                    <h1 className="header-main-content" >Our Services</h1>
                    <p className='header-body-content' >Getting started is the hardest part. We’re here to help you bring your digital products to life, expand your market presence, and scale your business—exactly the way you need.</p>
                    <button on className='service-btn' onClick={() => navigate("/contactUs")}>You got an idea? let's chat</button>
                    <img
                        src="./services.png" // Replace with your image URL
                        alt="Header visual"
                        className="header-img"
                        style={{
                            marginBottom: "120px", marginTop: "80px", paddingLeft: "60px",
                            paddingRight: "60px"
                        }}
                    />
                </header>
                <section style={{ background: "#FEE5E8", paddingTop: "120px", paddingBottom: "120px" }}>
                    <div className="container" style={{ background: "#FEE5E8" }}>
                        {sections.map((section, index) => (
                            <div key={index} className="row mb-5 align-items-start" style={{ background: "#FEE5E8" }}>
                                <div className="col-md-5 sticky-title" style={{ background: "#FEE5E8" }}>
                                    <h2 className="fw-bold">{section.title}</h2>
                                    <p style={{ textAlign: "justify", color: "#242424", fontWeight: "500" }}>{section.description}</p>
                                    <button onClick={() => navigate("/contactUs")} className="btn btn-danger btn-red-background" style={{ whiteSpace: "nowrap", width: "fit-content" }}>{section.buttonText}</button>
                                </div>
                                <div className="col-md-7 side-content-service-web">
                                    {section.columns.map((column, colIndex) => (
                                        <div key={colIndex} className="mb-4">
                                            <h3 style={{ color: "color: #242424", fontWeight: "500" }}>{column.subTitle}</h3>
                                            <p style={{ textAlign: "justify", color: "#4E4E4E" }}>{column.subDescription}</p> {/* Subtitle description */}
                                            < ul >
                                                {
                                                    column.points.map((point, pointIndex) => (
                                                        <li style={{
                                                            color: "#4E4E4E",
                                                            fontWeight: "400",
                                                            fontStyle: "normal"

                                                        }} key={pointIndex}>{point}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))
                        }
                    </div >
                </section >

                <div style={{ marginTop: "120px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ServiceCard />
                </div>
                <section style={{ marginTop: "120px", color: "white", padding: "60px 120px" }} className="service-tile-section-3">
                    <h2 style={{ textAlign: "center", marginBottom: "40px", color: "black", fontWeight: "600" }} className="mobile-getting-started-service">Getting started is easy!</h2>
                    <p style={{ textAlign: "center", marginBottom: "40px", color: "black" }} >
                        We believe in making collaboration seamless to your needs. Whether you’re looking for ongoing support or need guidance on specific challenges, our flexible service management options ensure we work in a way that suits you best:
                    </p>
                    <div
                        className='service-tile-mobile'
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "20px",
                        }}
                    >
                        {[
                            {
                                title: "Monthly Retainers",
                                hoverDescription: "Perfect for businesses seeking consistent, hands-on support to drive long-term growth and success.",
                                src: "./service1.png"
                            },
                            {
                                title: "Deliverable based",
                                hoverDescription: "Perfect for clearly defined projects like web design, photography,  pitch decks, product launches, or marketing campaigns, with transparent pricing tied to specific deliverables.",
                                src: "./service2.png"
                            },
                            {
                                title: "Hourly Advising Services",
                                hoverDescription: "Ideal for targeted advice sessions or tackling specific projects with expert insights. Priced by hours and style of advisory chosen.",
                                src: "./service3.png"
                            },
                            {
                                title: "Custom Servicing",
                                hoverDescription: `We also offer fully customized service options tailored to your unique needs. Whether it’s a mix of retainer support, deliverable-based projects, or hourly advising, we’re here to create a solution that works best for you.`,
                                src: "./service4.png"
                            }
                        ].map((service, index) => (
                            <div
                                key={index}
                                style={{
                                    color: "#262626",
                                    borderRadius: "30px",
                                    padding: "20px",
                                    maxWidth: "300px",
                                    textAlign: "left",
                                    minWidth: "300px"

                                }}
                                className="service-tile"
                            >
                                <div className="">
                                    <img src={service.src} alt="index" />
                                    <h3 style={{ marginBottom: "10px", marginTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>{service.title}</h3>
                                    <p style={{ textAlign: "left" }}>{service.hoverDescription}</p>
                                </div>
                                {/* <div className="hover-content">
                             <h3 style={{ color: "white", marginBottom: "10px", marginTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>{service.title}</h3>
                             <p style={{ fontSize: "12px", color: "white", overflow: "hidden", }}>{service.hoverDescription}</p>
                         </div> */}
                            </div>
                        ))}
                    </div>
                </section>
                <p>No matter the approach, we’re committed to aligning with your goals and delivering impactful results every step of the way. Let’s create something amazing together!</p>
                <section style={{ paddingTop: "120px", background: "#FFF5EE", display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <div className="text-client">Client Who trust Us</div>
                    <Card />
                    <div style={{ marginTop: "120px", marginBottom: "120px" }}>
                        <Card2 />
                    </div>
                </section>
            </div >
            <section style={{}}>
                <div className="image-container">
                    <img src="./polygon2.png" alt="polygun" style={{ position: "relative", }} />
                </div>
            </section>
        </>
    );
};

export default Services;
