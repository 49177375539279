import Card2 from "../body/card2";
import "./index.css";

const ContactUs = () => {
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Collect data from form fields
        const form = e.target;
        const formData = new FormData(form);

        const Sheet_Url =
            "https://script.google.com/macros/s/AKfycby1CJ9vjX-8RBkmy2zer-KLFqX4tXiNuYfZvguLej_jq6-P2w_L3DbuoxxsSzOVmYZiIA/exec";

        try {
            const response = await fetch(Sheet_Url, {
                method: "POST",
                body: formData,
            });

            if (response.ok) {
                console.log("Form submitted successfully!");
                alert("Thank you! We will get back to you soon."); // Show success message
                form.reset(); // Clear the form fields
            } else {
                alert("Oops! There was an error submitting your form. Please try again.");
                console.error("Error submitting the form:", response.statusText);
            }
        } catch (error) {
            alert("Oops! Something went wrong. Please try again.");
            console.error("Fetch error:", error);
        }
    };

    return (
        <>
            <div className="contact-form-container">
                <h1>Hey there!</h1>
                <p>We would love to have a conversation with you!</p>
                <div style={{ display: "flex", gap: "20px", padding: "20px" }} className="container-form-mini-container-mobile">
                    <div className="contact-form-mini-container" style={{ maxWidth: "252px" }}>
                        <p className="contact-us-text">Office</p>
                        <p className="contact-us-text-2" style={{ textAlign: "left" }}>⁠We are located in Silicon Valley.</p>
                    </div>

                    <div className="contact-form-mini-container" >
                        <p className="contact-us-text">Call us now</p>
                        <a href={"tel:+1-650-429-8012"} className="contact-us-text-2"> +1-650-429-8012</a>
                    </div>
                </div>

                <form className="contact-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input type="text" name="firstName" placeholder="first name" required />
                        <input type="text" name="lastName" placeholder="last name" required />
                    </div>
                    <div className="form-group">
                        <input type="email" name="email" placeholder="email id" required />
                        <input type="tel" name="phoneNumber" placeholder="phone number" required />
                    </div>
                    <div className="form-group">
                        <input type="text" name="companyName" placeholder="company name" required />
                        <select name="department" required style={{ color: "#858585" }}>
                            <option value="" disabled selected>
                                Select your department
                            </option>
                            <option value="Digital">Digital</option>
                            <option value="Marketing">Marketing</option>
                            <option value="Innovation">Innovation</option>
                            <option value="Technology">Technology</option>
                            <option value="Experience">Experience</option>
                            <option value="Product">Product</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <select name="interest" required style={{ color: "#858585" }}>
                            <option value="" disabled selected>
                                Area of interest
                            </option>
                            <option value="Product Development">Product Development</option>
                            <option value="Marketing">Marketing</option>
                            <option value="Branding">Branding</option>
                            <option value="Video">Video</option>
                            <option value="Video">Fundraising</option>
                            <option value="Video"> CFO Support</option>
                            <option value="Video"> Legal</option>
                            <option value="Video"> Don’t Know Yet</option>
                        </select>
                        <select name="source" required style={{ color: "#858585" }}>
                            <option value="" disabled selected>
                                Where did you hear about us
                            </option>
                            <option value="Email">Email</option>
                            <option value="Linkedin">LinkedIn</option>
                            <option value="Phone">Phone</option>
                            <option value="Google Search">Google Search</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <textarea name="message" placeholder="message" rows="4" required></textarea>
                    </div>
                    <button type="submit">Submit</button>
                </form>

                <div style={{ marginBottom: "120px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Card2 />
                </div>
            </div>
            <section>
                <div className="image-container">
                    <img src="./polygon2.png" alt="polygon" style={{ position: "relative" }} />
                </div>
            </section>
        </>
    );
};

export default ContactUs;
