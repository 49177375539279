import Card2 from "../body/card2";
import FounderNote from "./founderNote";
import "./index.css"
import TeamSection from "./teammember";
import { useNavigate } from "react-router-dom";
const Section1AboutUs = () => {
    const navigate = useNavigate();
    const services = [
        { src: './dummy1.png', alt: 'Service 1', className: "margin-top-apply" },
        { src: './dummy2.png', alt: 'Service 2', className: "" },
        { src: './dummy3.png', alt: 'Service 3', className: "margin-top-apply" },
        { src: './dummy4.png', alt: 'Service 4', className: "" }
    ];
    return (
        <div className="container-background-about-us">
            <div className="container  container-margin-top">
                {/* Main Text Section */}
                <div className="text-center mb-5 main-text-wrapper">
                    <h1 className="fw-bold text-main-about-us">Maximizing Your Business Growth</h1>
                    <p className="text-section-about-us">
                        At our core, we are a diverse team of experts united by a passion for driving growth and innovation.
                        Whether you’re looking to scale, refine your brand, or embrace cutting-edge technology, we’re here to guide you.
                    </p>
                    <div className="d-flex justify-content-center gap-3 mt-4">
                        <button onClick={() => navigate("/contactUs")} className="btn btn-about-us btn-white-background2">Let’s Talk!</button>
                        <button className="btn btn-danger btn-about-us-2 btn-red-background" onClick={() => navigate("/contactUs")}>Schedule a Call Now</button>
                    </div>
                </div>


                {/* Service Cards */}
                <div className="row g-4 service-wrapper">
                    {services.map((service, index) => (
                        <div key={index} className={`col-12 col-sm-6 col-lg-3 ${service.className}`}>
                            {/* <div className={`service-card h-100 border-0 shadow ${service.className}`}> */}
                            <img src={service.src} alt={service.alt} className="card-img-top" />

                            {/* </div> */}
                        </div>
                    ))}
                </div>
            </div>
            <section>
                <div className="image-container">
                    <img src="./polygon2.png" alt="polygun" style={{ position: "relative", }} />
                </div>
            </section>
            <FounderNote />
            <section>
                <div className="image-container">
                    <img src="./polygun.png" alt="polygun" style={{ position: "relative", }} />
                </div>
            </section>
            <TeamSection />
            <div style={{ marginBottom: "120px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Card2 />
            </div>
            <section>
                <div className="image-container">
                    <img src="./polygon2.png" alt="polygun" style={{ position: "relative", }} />
                </div>
            </section>
        </div>
    )
}

export default Section1AboutUs