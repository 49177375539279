import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import './index.css';

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const isHomePage = location.pathname === '/';
    const isAboutPage = location.pathname === '/aboutUs';
    const isServicePage = location.pathname === '/ourServices';
    const isContactPage = location.pathname === '/contactUs';

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => {
        setIsNavCollapsed(!isNavCollapsed);
    };

    const closeNav = () => {
        setIsNavCollapsed(true);
    };

    return (
        <>
            <nav
                className="navbar navbar-expand-lg body1"
                style={{ backgroundColor: !isHomePage ? '#FFF5EE' : '#EE4358', padding: '20px 50px' }}
            >
                <div className="container-fluid">
                    <Link
                        className="navbar-brand d-flex align-items-center"
                        to="/"
                        style={{ color: 'white', fontWeight: 'bold', display: 'flex', gap: '10px', zIndex: '1' }}
                    >
                        <img src={`${!isHomePage ? './red-logo.png' : './logo.png'}`} alt="logo" style={{ height: '40px' }} />
                        <span className={`${!isHomePage ? 'text-nevItems' : ''}`}>
                            SVM <span style={{ fontWeight: 'normal' }}>Ventures</span>
                        </span>
                    </Link>

                    {/* Toggler for mobile */}
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded={!isNavCollapsed}
                        aria-label="Toggle navigation"
                        style={{ border: 'none', color: 'white' }}
                        onClick={handleNavCollapse}
                    >
                        <span className="navbar-toggler-icon" style={{ color: 'white' }}></span>
                    </button>

                    {/* Collapsible Menu */}
                    <div
                        className={`collapse navbar-collapse navbar-center-align-content ${isNavCollapsed ? '' : 'show'}`}
                        id="navbarSupportedContent"
                    >
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{ marginLeft: 'auto', gap: '20px' }}>
                            <li className="nav-item">
                                <Link
                                    className={`nav-link ${isAboutPage ? 'text-nevItems' : ''}`}
                                    style={{ fontWeight: 'bold', color: isHomePage ? 'white' : '#0A0A0A' }}
                                    to="/aboutUs"
                                    onClick={closeNav} // Close dropdown on click
                                >
                                    About us
                                    {isAboutPage ? (
                                        <div style={{ height: '2px', background: '#EE4358' }} className="mobile-border-selection"></div>
                                    ) : null}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className={`nav-link ${isServicePage ? 'text-nevItems' : ''}`}
                                    style={{ fontWeight: 'bold', color: isHomePage ? 'white' : '#0A0A0A' }}
                                    to="/ourServices"
                                    onClick={closeNav} // Close dropdown on click
                                >
                                    Services
                                    {isServicePage ? (
                                        <div style={{ height: '2px', background: '#EE4358' }} className="mobile-border-selection"></div>
                                    ) : null}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className={`nav-link ${isContactPage ? 'text-nevItems' : ''}`}
                                    style={{ fontWeight: 'bold', color: isHomePage ? 'white' : '#0A0A0A' }}
                                    to="/contactUs"
                                    onClick={closeNav} // Close dropdown on click
                                >
                                    Contact us
                                    {isContactPage ? (
                                        <div style={{ height: '2px', background: '#EE4358' }} className="mobile-border-selection"></div>
                                    ) : null}
                                </Link>
                            </li>
                        </ul>
                        <button
                            className={`ms-lg-3 ${!isHomePage ? 'btn-header-red' : 'btn-header'}`}
                            type="button"
                            onClick={() => {
                                navigate('./contactUs');
                                closeNav(); // Close dropdown after navigation
                            }}
                        >
                            Let's Talk
                        </button>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Header;
